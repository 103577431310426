import request from '@/utils/request';

//查询用户分组列表
export function getUserlist(params) {
  return request({
    url: `/seller/members/memberGroup`,
    method: 'get',
    loading: false,
    params
  })
}

//查询用户分组列表
export function getUserGroups() {
  return request({
    url: `/seller/members/memberGroup/listUserGroup`,
    method: 'get',
  })
}

// 添加用户分组
export function addUserfz(params) {
  return request({
    url: `/seller/members/memberGroup`,
    method: 'post',
    data: params
  })
}

// 删除用户分组表
export function deleteUserfz(id) {
  return request({
    url: `/seller/members/memberGroup/${id}`,
    method: 'delete',

  })
}

// 修改用户分组表
export function updateUserfz(id, params) {
  return request({
    url: `/seller/members/memberGroup/${id}`,
    method: 'put',
    data: params

  })
}

// //删除消息列表
// export function removeMessage(ids) {
// 	return request({
// 		url: `/seller/shops/shop-notice-logs/${ids}`,
// 		method: 'delete',
//         loading: false
// 	})
// }

// //获取消息列表
// export function editMessage(ids) {
// 	return request({
// 		url: `/seller/shops/shop-notice-logs/${ids}/read`,
// 		method: 'put',
//         loading: false
// 	})
// }
//查询用户列表
export function getMemberList(params) {
  return request({
    url: '/seller/members',
    method: 'get',
    params

  })
}

//查询一个会员信息
export function memberMsg(id) {
  return request({
    url: `/seller/members/${id}`,
    method: 'get',
  })
}

//添加用户
export function addMember(params) {
  return request({
    url: '/seller/members/sx/addMember',
    method: 'post',
    data: params

  })
}

//恢复会员
export function reinstatement(id) {
  return request({
    url: `/seller/members/recoveryMembers/${id}`,
    method: 'post'
  })
}

//修改用户
export function changeMember(id, params) {
  return request({
    url: `/seller/members/sx/${id}`,
    method: 'put',
    data: params

  })
}

//删除/用户
export function deleteMember(id) {
  return request({
    url: `/seller/members/${id}`,
    method: 'delete',
  })
}

//禁用
export function closeMember(ids) {
  return request({
    url: `/seller/members/disabledMembers/${ids}`,
    method: 'post',

  })
}

//修改用户分组
export function changeGroup(ids, params) {
  return request({
    url: `/seller/members/editGroup/${ids}`,
    method: 'post',
    data: params
  })
}

//查询用户余额用户列表
export function memberMoney(params) {
  return request({
    url: '/seller/members/money/list',
    method: 'get',
    params
  })
}
//查询中石油用户余额用户列表
export function transactionList(params) {
  return request({
    url: '/seller/verifyRecord/transactionList',
    method: 'get',
    params
  })
}
//批量导入会员和积分信息
export function importMember(params) {
  return request({
    url: '/seller/members/charge/importSXEnableMoney',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    data: params
  })
}

//查询商家给用户充值列表
export function chargeList(params) {
  return request({
    url: '/seller/members/charge',
    method: 'get',
    params
  })
}

//店铺查询用户信息列表
export function dpgetMemberList(params) {
  return request({
    url: '/seller/members/charge/getMemberList',
    method: 'get',
    loading: true,
    params
  })
}

//商家给已有用户充值信息
export function dpAddCharge(ids, params) {
  return request({
    url: `/seller/members/charge/addCharge/${ids}`,
    method: 'post',
    data: params
  })
}

//商家导入会员和充值信息
export function importChargeMoney(params) {
  return request({
    url: '/seller/members/charge/importChargeMoney',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    data: params
  })
}
/** 双闪商城  商家导入会员和充值信息*/
export const importSXChargeMoney = (params) => request({
  url: '/seller/members/charge/importSXChargeMoney',
  headers: { 'Content-Type': 'application/json' },
  method: 'post',
  data: params
})
//查询商家给用户充值详情列表
export function getDetail(params) {
  return request({
    url: '/seller/members/charge/getDetail',
    method: 'get',
    params
  })
}

//删除商家给用户充值详情表
export function deleteetail(id) {
  return request({
    url: `/seller/members/charge/detail/${id}`,
    method: 'delete',
  })
}

//给用户发送短信通知
export function sendMessage(id) {
  return request({
    url: `/seller/members/charge/sendMessage/${id}`,
    method: 'put',
  })
}

// 查询用户当前的兑换卡记录
export function getUserChange(params) {
  return request({
    url: 'seller/card/combo/memberComboKeys',
    method: 'get',
    params
  })
}

// 现金商城查询当前用户的绑卡记录
export function getUserChangeCardList(params) {
  return request({
    url: '/seller/card/cardKeysList',
    method: 'get',
    params
  })
}
// 现金商城查询当前用户的优惠券领取记录列表
export function getUserMemberKeyList(params) {
  return request({
    url: '/seller/couponKey/memberKeyList',
    method: 'get',
    params
  })
}

/**
 * 获取用户统计数据
 */
export function getUserStaticData() {
  return request({
    url: '/seller/members/census',
    method: 'get'
  })
}
//修改用户核销权限
export function putMemberVerify (member_id, data) {
  console.log(data);
  return request({
    url: `/seller/members/putMemberVerify/${member_id}`,
    method: 'put',
    data:data
  })
}
/**
 * 查询收益变动记录列表
 * @param params 
 */
export function getEarningsList(params) {
  return request({
    url: '/seller/distribution/V2/mdi',
    method: 'get',
    params
  })
}
/**
 * 查询收益变动记录详情
 * @param id 
 */
export function getEarningsInfo(id) {
  return request({
    url: `/seller/distribution/V2/mdi/${id}`,
    method: 'get'
  })
}
/**
 * 查询下级分销商列表
 * @param params 
 */
export function getLowerList(params) {
  return request({
    url: '/seller/distribution/V2/d/lower-lv1-list-page',
    method: 'get',
    params
  })
}